import CloudFunctions from "@/api/cloudFunctions/CloudFunctions";
import { auth } from "@/api/firebase/firebase.config";
import { Spinner } from "@/components";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface Response {
  status: "success" | "failed";
  reason?: string; // if status is failed
  token?: string; //if status is success
}

export const AuthTwitchRedirect = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [loading, setLoading] = useState<boolean>(false);
  const code = urlParams.get("code");
  const navigate = useNavigate();

  const handleAuth = async () => {
    setLoading(true);
    await CloudFunctions.callCloudFunction("authenticateWithTwitchCode", {
      twitchCode: code,
      platform: brand.brandValue,
    })
      .then(async (response) => {
        if (response.token && response.status === "success") {
          await auth
            .signInWithCustomToken(response.token)
            .then((res) => {
              if (res?.user) {
                toast.success(
                  "Login with twitch successfull, redirecting to home page."
                );
                return navigate("/");
              }
            })
            .catch(() => {
              toast.error(
                response.reason ||
                  "Failed to login with token, please try again later"
              );
              return navigate("/");
            });
        }
      })
      .catch((err) => {
        toast.error("Failed to authenticate, please try again later");
        return navigate("/");
      });

    return navigate("/");
  };

  useEffect(() => {
    if (!loading && code) {
      handleAuth();
    }
  }, [code]);

  return (
    <div className="auth__redirect">
      <h2>Authenticating with twitch...</h2>
      <Spinner />
    </div>
  );
};
