import { useLeaderboardData } from "./helperFunctions/useLeaderboardData";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { Challenge1337PageLeaderboard } from "./components/1337.challenge.page.leaderboard";
import { Challenge1337PageLeaderboardMentions } from "./components/1337.challenge.page.leaderboard.mentions";
import { ReactComponent as IconElgiganten } from "../../../branchAndBrandSpecific/utils/brandVariables/assets/elgiganten/elgiganten.svg";
import QRCode from "react-qr-code";
import { ReactComponent as IconScanCode } from "../../../branchAndBrandSpecific/features/1337-challenge/assets/scanThisCode.svg";

export const Challenge1337Pagelarge = () => {
  const currentUser = useCurrentUserProfile((s) => s.user);

  const { data, attemptCount, success } = useLeaderboardData({ currentUser });

  return (
    <div className="leet-challenge leet-challenge-lg">
      <section className="leet-challenge-lg__heading">
        <IconElgiganten />
      </section>

      <div className="leet-challenge-lg__leaderboard">
        <section className="leet-challenge__leaderboard">
          <h1>Stop the timer at 1337</h1>
          <Challenge1337PageLeaderboard data={data?.hasScore1337} />
        </section>

        <section className="leet-challenge__mentions">
          <div className="leet-challenge-lg__scan-here">
            <IconScanCode />
          </div>

          <div className="leet-challenge-lg__code">
            <QRCode
              value="https://elgiganten.iplay.dk/1337-redirect"
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            />
          </div>

          <Challenge1337PageLeaderboardMentions
            data={data?.honorableMentions}
            hasWinners={data?.hasScore1337?.length === 0 ? false : true}
          />
        </section>
      </div>
    </div>
  );
};
