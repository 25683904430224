// THIS FOLDER SHOULD ONLY CONTAIN HELPERFUNCTIONS THAT ARE USED ACROSS DIFFERENT FEATURES
// SUCH AS FOLLOWING/UNFOLLOWING A USER IS USED IN DIFFERENT FEATURES
import QueryDB_Notifications from "./../../api/queryDB/notifications/index";
import QueryDB from "./../../api/queryDB/common/index";
import { createToast } from "../toaster/createToast";
import QueryDBProfiles from "@/api/queryDB/profiles";
import { Profile } from "@/models/database/profiles/profile.model";
import {
  cleanEntityName,
  convertCleanEntityNameToSlug,
  normalizeString,
} from "../constants/stringProcessing";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/app";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { useAuthPersistState } from "@/store/auth/auth.store";
import mockUsernames from "@/features/auth/utils/mockUsernames";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { avatarsRaw } from "@/features/auth/utils/avatars";
import QueryRTDB from "@/api/queryDB/realTimeDB";
import mockUsernamesAddons from "@/features/auth/utils/mockUsernamesAddons";

// Function to update wether you are following someone or not, this will return true or false based on the previous condition

export const handleFollow = async (
  isFollower: boolean | undefined,
  setFollower: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  userToFollowUid: string,
  currentUserUid: string
) => {
  if (isFollower === undefined) {
    createToast({
      type: "error",
      message: "Oops, Something went wrong",
    });
    return false;
  }

  if (isFollower) {
    setFollower(false);
    // setCreatorFollowers((prev) => prev - 1);
    await QueryDB.deleteJunction(
      "usersFollowers",
      `${userToFollowUid}`,
      "followers",
      `${currentUserUid}`
    ).then(() => {
      createToast({
        type: "info",
        message: "No longer following challenge creator",
      });
    });
  } else {
    setFollower(true);
    // setCreatorFollowers((prev) => prev + 1);
    await QueryDB.createJunction(
      "usersFollowers",
      `${userToFollowUid}`,
      "followers",
      `${currentUserUid}`
    ).then(() => {
      createToast({
        type: "success",
        message: "Following challenge creator",
      });
    });
    await QueryDB_Notifications.createNewNotification(
      `${userToFollowUid}`,
      `${currentUserUid}`,
      `${userToFollowUid}`,
      "friends-following",
      // notificationsTextMapping["friends-following"]
      ""
    );
  }
};

// Update the user data that is available in the useCurrentUserProfile hook

// setUserProfile = useCurrentUserProfile((s) => s.setUser);
// currentUser = useCurrentUserProfile((s) => s.user);

export const refreshUser = async (
  setUserProfile: (newUser: Profile | null) => void,
  currentUser: Profile
) => {
  const _userProfile = await QueryDBProfiles.getUserBasedOnUid(currentUser.uid);

  if (_userProfile) {
    setUserProfile(_userProfile);
  }
};
export const convertStringToSlug = (value: string) => {
  const formattedString = normalizeString(
    convertCleanEntityNameToSlug(cleanEntityName(value))
  );
  return formattedString;
};

export const useConnectWithTokenAndRedirect = async (token) => {
  const navigate = useNavigate();
  const setProfile = useCurrentUserProfile((s) => s.setUser);
  const setUser = useAuthPersistState((state) => state.setUser);
  const userCredential = await firebase.auth().signInWithCustomToken(token);
  const userDocument = await firebase
    .firestore()
    .collection("profiles")
    .doc(userCredential.user?.uid)
    .get();

  if (userCredential && userDocument?.data()?.userName) {
    setProfile(userDocument as any);
    setUser(userCredential as any);

    return navigate("/");
  }
  createToast({
    type: "info",
    message: "User not found",
  });

  if (userCredential && !userDocument?.data()?.userName) {
    localStorage.setItem("authEmailLocal", userDocument?.data()?.email);

    return navigate("/enter-username");
  }

  if (!userCredential && !userDocument?.data()?.userName) {
    return navigate("/login");
  }
};

export const handleLogout = async (
  setUserProfile: (newUser: Profile | null) => void
) => {
  // DISABLE LOGGIN OUT
  // if (brand.event.isEvent) {
  //   return;
  // }

  // localStorage keys to remove
  const storageToRemove = [
    "userProfile",
    "cloud_functions_for_list_data",
    "persistance_cloud_functions",
    "firestore_query_persistance",
    "firebase_currentUser_profile",
    "persistance_firestore_document_data",
    "activeTab",
    "author_profiles_storage",
    "teams_last_tab",
    "WOG_team_members",
    "WOG_team_feed",
    "WOG_my_teams",
    "WOG_discover_teams",
    "WOG_team_members",
    "WOG_team_data",
    "wog_news_data",
    "WOG_news_all",
    "WOG_news_featured",
  ];

  // Remove any local data from localstorage
  storageToRemove.map((storageItem) => {
    localStorage.removeItem(storageItem);
  });

  // Set user offline
  await QueryRTDB.setUserStatus("offline");
  setUserProfile(null); // Remove profile from storage
  await firebase.auth().signOut();

  return;
};

export const createQuickAccountUser = async (
  setUserProfile: (newUser: Profile | null) => void
) => {
  // Just to make sure, run a log out to remove any left over data
  await handleLogout(setUserProfile);

  const initial =
    mockUsernames[Math.floor(Math.random() * mockUsernames.length) + 1];

  const extension =
    mockUsernamesAddons[
      Math.floor(Math.random() * mockUsernamesAddons.length) + 1
    ];

  const number = Math.floor(Math.random() * 9) + 1;

  // ---------------------------
  const testingExtension = ""; // REMOVE VALUE FOR EVENTS
  // ---------------------------

  const username = `${initial}${extension}${brand.brandIntials}${number}${testingExtension}`;

  const avatar = avatarsRaw[Math.floor(Math.random() * avatarsRaw.length)];

  const slug = normalizeString(
    convertCleanEntityNameToSlug(cleanEntityName(username))
  );
  const client = localStorage.getItem("client") || brand.brandValue;

  const response = await firebase
    .auth()
    .signInAnonymously()
    .then(async (res) => {
      const userData = {
        slug,
        region: "Europe",
        userName: username,
        uid: res.user?.uid,
        fullName: "",
        profileDescription: "",
        favoriteGames: [],
        lang: brand.defaultLanguage,
        profileLogoUrl: avatar,
        theme: brand.brandTheme,
        platform: brand.brandValue as any,
        allPlatforms: [brand.brandValue, client],
        profileLogoUrlHistory: [],
        country: "",
        followersCount: 0,
        friendsCount: 0,
        termsAccepted: false,
        isTestAccount:
          window.location.pathname.includes("localhost:") || testingExtension
            ? true
            : false,
      } as Profile;

      QueryDB.createNewDoc("profiles", userData, res.user?.uid).then(
        async () => {
          setUserProfile(userData);
          await firebase.auth().currentUser?.reload();
          return window.location.reload();
        }
      );
      if (window.location.pathname.includes("localhost:")) {
        createToast({ type: "info", message: "CREATING USER" });
      }
      return userData;
    })

    .catch((err) => {
      return "error";
    });

  return response;
};
