import { FC } from "react";
import TrackerCard from "./components/TrackerCard";
import CtaCard from "./components/CallToActionCard";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import { brand } from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import Hero from "@/components/PageHero/PageHero";
import LandingCallToAction from "../landing/components/CallToAction";
import Logitech from "../landing/assets/logitech.webp";
import TCL from "../landing/assets/tcl.webp";
import PCSpecialist from "../landing/assets/pcspecialist.webp";
import jblImage from "../../../branchAndBrandSpecific/features/partners/assets/jbl_challenge.webp";
import racingImage from "../landing/assets/elgRacing.webp";
import elgigantenCS2 from "../landing/assets/cs2elgiganten.webp";
import { BestGamerHomePage } from "./../../../branchAndBrandSpecific/features/home/bestgamer/BestGamerHomePage";
import { siteRoutes } from "@branchAndBrandSpecific/routes/siteRoutesList";
import hero from "@/components/PageHero/utils/heroVariables";
import Challenge1337Page from "./../../../branchAndBrandSpecific/features/1337-challenge/1337.challenge.page";
import btn1337 from "../landing/assets/1337btn.webp";

const HomePage: FC = (): JSX.Element => {
  // Page Document Title
  useDocumentTitle("Welcome - Home");

  // Variables
  const hasTracker = brand.hasFeature.getstarted;
  const hasHero = brand.hasFeature.hero;

  // During LiveStream we don't have a home page
  if (brand.brandValue === "ELGIGANTEN") {
    return <Challenge1337Page />;
  }

  if (brand.brandValue === "BESTGAMER") {
    return <BestGamerHomePage />;
  }

  return (
    <div className="home">
      {hasHero && (
        <Hero
          image={{
            src: hero.image.src,
            alt: hero.image.alt,
            title: hero.image.title,
            mobile: hero.image.mobile,
          }}
          heading={hero.heading}
          description={hero.description}
          btn={{
            link: hero.btn.link,
            label: hero.btn.label,
          }}
          authorName={hero.authorName}
          postedDate={""}
        />
      )}
      {brand.brandValue !== "ELGIGANTEN" && (
        <div
          className={`home__grid ${
            !hasTracker ? "home__grid--no-tracker" : ""
          }`}
        >
          {hasTracker && <TrackerCard />}
          <div
            className={`home__grid home__grid--small ${
              !hasTracker ? "home__grid--size" : ""
            }`}
          >
            <CtaCard />
          </div>
        </div>
      )}
      {brand.brandValue === "ELGIGANTEN" && (
        <>
          <LandingCallToAction
            title={"CS2: Er du klar til en 1v1?"}
            desc={
              "Tag udfordringen op i Counter-Strike 2! Sæt dig til rette, og gå direkte i duel mod dine venner eller en tilfældig modstander. Bevis dine skills i intense 1v1-kampe og vis, hvem der er den bedste på serveren."
            }
            image={elgigantenCS2}
            link="/partners/cs2"
            label={"Læs mere"}
            float="left"
            arrow="1"
            arrowClr="green"
          />
          <LandingCallToAction
            title={"F1® 24: Bliv den hurtigste på banen"}
            desc={
              "Er du hurtig nok til at komme på leaderboardet? Prøv en Formel 1 racer i F1® 24 og sæt din bedste omgangstid. Kør på en ikonisk bane, og kæmp for den øverste placering på leaderboardet. Den hurtigste tid vinder mere end bare æren - er du klar til at træde speederen i bund?"
            }
            image={racingImage}
            link="/partners/racing"
            label={"Læs mere"}
            float="right"
            arrow="2"
            arrowClr="green"
          />
          <LandingCallToAction
            title={"Test din præcision"}
            desc={
              "Tror du, du har, hvad der skal til for at stoppe uret præcis på 1337 millisekunder? Det lyder nemt, men det kræver perfekt timing og nerver af stål. Tryk på knappen for at starte uret, og stop det igen, når du tror, det rammer 1337. Kun de mest præcise deltagere kan mestre denne udfordring - er du en af dem?"
            }
            image={btn1337}
            link="/1337-challenge"
            label={"Test dine reaktionsevner"}
            float="left"
            // arrow="3"
            // arrowClr="green"
          />
          {/* <LandingCallToAction 
              title={"1337 Button Challenge"}
              desc={"Can you hit the stop button at exactly 1337? Test your skills participate in a raffle for a new vlogging camera! The challenge is simple: hit the stop button at exactly 1337. The closest to the target wins a prize. Are you up for the challenge?"}
              image={PCSpecialist}
              link={"/1337-challenge"}
              label={"Try it out now"}
              float="right"
            /> */}
          {/* <LandingCallToAction 
              title={"Try your Ultimate Gaming PC with PCSpecialist!"}
              desc={"Experience unrivalled Gaming PC performance in your favourite games and compete in online action with Gaming PCs from the leading custom PC provider. Our award-winning Gaming PC ranges are built with the latest technology from world-renowned brands such as Intel, AMD & Nvidia, so you can enjoy gameplay in the highest settings possible."}
              image={PCSpecialist}
              link="/signup"
              label={"Try it out now"}
              float="left"
            /> */}
        </>
      )}
    </div>
  );
};

export default HomePage;
