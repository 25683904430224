import React from "react";
import { CS2LogProps } from "./types/CS2Log.types";
import getCS2Log from "./hooks/getCS2Log";
import { Events } from "../partners/tcl.logs.page";
import { cs2Weapons } from "@/utils/constants/cs2Weapons";

const CS2Log: React.FC<CS2LogProps> = ({ client }) => {
  const { usersData, logs } = getCS2Log(client);
  const player1Score = usersData?.player1?.kills || 0;
  const player2Score = usersData?.player2?.kills || 0;

  const getTime = (time) => {
    if (!time) {
      return "00:00";
    }

    const date = new Date(time * 1000);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  return (
    <div className="cs2log">
      <div className="cs2log__meta">
        <section className="cs2log__user">
          <h2>Champion</h2>
        </section>
        <div className="cs2log__score">
          <p>{player1Score}</p> <p>:</p> <p>{player2Score}</p>
        </div>
        <section className="cs2log__user">
          <h2>Challenger</h2>
        </section>
      </div>
      <div className="cs2log__logs">
        <ul className="logs">
          {logs &&
            Object.values(logs)
              ?.slice(0, 4)

              .map((data: Events, index) => {
                const player = data.player === "Player 1" ? 1 : 0;
                const key = index;

                return (
                  <li
                    key={key}
                    className={`logs__item ${
                      player === 1 ? "align-left" : "align-right"
                    }`}
                  >
                    <div className="item">
                      <section className="item__info">
                        {player === 1 ? (
                          <>
                            <p>
                              {cs2Weapons[data.weaponName] || data.weaponName}{" "}
                            </p>
                            <div>{data.wasHeadshot ? "Headshot" : "Kill"}</div>
                          </>
                        ) : (
                          <>
                            <div>{data.wasHeadshot ? "Headshot" : "Kill"}</div>
                            <p>
                              {cs2Weapons[data.weaponName] || data.weaponName}{" "}
                            </p>
                          </>
                        )}
                      </section>
                      <section className="item__time">
                        <time>{getTime(data.timestamp)}</time>
                      </section>
                    </div>
                  </li>
                );
              })}
        </ul>
      </div>
    </div>
  );
};

export default CS2Log;
